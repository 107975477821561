<template>
  <div>
    <Menu :loginInfo="loginInfo" />
    <h2 class="my-4">담당자 관리</h2>
    <h3 class="ms-3 my-4">담당자 목록</h3>
    <div v-if="managerDelete.ok" class="alert alert-info">{{ managerDelete.ok }} 담당자를 삭제했습니다.</div>
    <table class="table table-striped align-middle text-center">
      <thead>
        <tr>
          <th scope="col">No.</th>
          <th scope="col">담당자 코드</th>
          <th scope="col">쿠폰 수</th>
          <th scope="col">관리자 메모</th>
          <th scope="col">수정</th>
          <th scope="col">삭제</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item of managerList" :key="item.num">
          <td>{{ item.num }}</td>
          <td>{{ item.id }}</td>
          <td>{{ item.used }} / {{ item.coupon }}</td>
          <td class="text-start">{{ item.memo }}</td>
          <td><a class="btn btn-primary" :href="'setting-' + item.id + '.html'">수정</a></td>
          <td>
            <span v-if="item.is_admin">관리자 계정</span>
            <button v-if="!item.is_admin" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal" @click="managerDeleteClick(item.id)">삭제</button>
          </td>
        </tr>
        <tr v-if="!managerList.length">
          <td colspan="5">자료없음</td>
        </tr>
      </tbody>
    </table>
    <hr class="my-5">
    <h3 class="ms-3 my-4">담당자 생성</h3>
    <div class="row pb-3">
      <div class="col-3">
        <label class="text-secondary">담당자 코드</label>
        <input class="form-control" v-model="newManager.item.id" />
        <small class="text-muted">로그인ID로 사용됩니다.</small>
      </div>
      <div class="col-3">
        <label class="text-secondary">초기 비밀번호</label>
        <input class="form-control" v-model="newManager.item.pw" />
        <small class="text-muted">담당자 스스로 변경할수 있습니다.</small>
      </div>
      <div class="col-6">
        <label class="text-secondary">관리자 메모</label>
        <input class="form-control" v-model="newManager.item.memo" />
        <small class="text-muted">해당 담당자 스스로는 볼 수 없는 관리자 전용 메모입니다.</small>
      </div>
    </div>
    <div v-if="newManager.require || newManager.ok" class="row">
      <div class="col">
        <div v-if="newManager.require" class="alert alert-danger">필수 입력입니다 : {{ newManager.require }}</div>
        <div v-if="newManager.ok" class="alert alert-info">{{ newManager.ok }} 담당자를 생성했습니다.</div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col text-center">
        <button type="submit" class="btn btn-primary" @click="newManagerClick">담당자 생성</button>
      </div>
    </div>
    <div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalLabel">담당자 삭제</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            담당자 {{ managerDelete.target }} 삭제하시겠습니까?
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">취소</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" @click="managerDeleteClick(null)">확인</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { ref, reactive, watch } from 'vue';
import Menu from '@/components/Menu.vue';



export default {
  components: {
    Menu
  },
  setup() {
    const loginInfo = reactive({});
    const managerList = reactive([]);
    const newManager = reactive({
      require: null,
      item: {
        pw: '1234'
      }
    });

    const managerListRequest = async () => {
      try{
        let res = await backend({ action: 'managerList' });
        if(await loginCheck(loginInfo, res, true)) return;
        managerList.length = 0;
        res.managerList.forEach((manager) => managerList.push(manager));

      }catch (e){
        console.log(e);
      }
    }
    managerListRequest();

    watch(managerList, (value) => value.forEach((manager, idx) => manager.num = idx + 1), { deep: true });

    const managerDelete = reactive({});
    const managerDeleteClick = async (manager) => {
      if(manager) managerDelete.target = manager;
      else {
        await backend({
          action: 'managerDelete',
          manager: managerDelete.target
        });
        managerListRequest();
        managerDelete.ok = managerDelete.target;
      }
    };

    const newManagerClick = async () => {
      try{
        if(!newManager.item.id) return newManager.require = '담당자 코드';
        if(!newManager.item.pw) return newManager.require = '초기 비밀번호';
        await backend({
          action: 'newManager',
          newManager: newManager.item
        });
        managerListRequest();
        newManager.ok = newManager.item.id;
      }catch(e){
        console.log(e);
      }
    };

    return { loginInfo, managerList, newManager, managerDelete, managerDeleteClick, newManagerClick };
  }
}
</script>
